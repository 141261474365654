:root {
  --color-1: rgb(248, 187, 160);
  --color-2: rgb(229, 76, 240);
  --color-3: rgb(42, 42, 222);
  --color-4: rgb(235, 35, 35);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(var(--color-3), var(--color-4));
}

#drum-machine {
  border: 4px solid var(--color-2);
  display: flex;
  width: 700px;
  padding: 24px;
  background-color: var(--color-1);
  border-radius: 10px;
  box-shadow: 2px 2px 10px black;;
}

#drum-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: var(--color-1);
}

.drum-pad {
  width: 120px;
  height: 100px;
  background-color: rgb(153, 180, 231);
  box-shadow: 2px 2px 6px rgb(111, 102, 102);
  border: 3px;
  margin: 4px;
  border-radius: 10px;
}

.push {
  width: 119px;
  height: 99px;
  box-shadow: 2px 2px 6px rgb(165, 151, 151);
}

.drum-pad, #display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 800;
  font-size: 20px;
}

#display {
  border: 3px solid orange;
  width: 300px;
  margin-left: 12px;
  border-radius: 10px;
}

#name {
  background-color: var(--color-2);
  width: 186px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  border-radius: 10px;
}

#sound-control {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.switch {
  background-color: black;
  width: 68px;
  height: 36px;
  border: 2px solid black;
  position: relative;
  border-radius: 20px;
  box-shadow: 2px 2px 4px black;;
}

.select-left, .select-right, .select-off {
  width: 32px;
  height: 32px;
  background-color: var(--color-3);
  position: absolute;
  border-radius: 50%;
}
.select-right {
  right: 0;
  background-color: darkorange;
}

.select-off {
  right: 0;
  background-color: var(--color-4);
}
